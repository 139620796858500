const messages = {
  FUND_TITLE: 'Благодійна організація «Благодійний фонд «УКРАЇНА РАЗОМ ПЕРЕМОЖЕ»',
  HELP_FOR_ALL: 'Допомогти усім, \n' + 'хто цього потребує',
  OUR_GOAL: 'Наша мета – безоплатна допомога людям, які потребуюсь соціального захисту',
  LIFE_BEFORE_WAR: 'Життя не може бути таким самим, як до 24.02.2022, але його можна зробити краще',
  CREATED_FUND: 'Заснування фонду ',
  IMAGE: 'зображення',
  CREATED_FUND_DESC:
    'Фонд заснований в 2022 році внаслідок військової агресії російської федерації. ' +
    'Наша мета -  надання допомоги усім, хто цього потребує у цих складних умовах, тим, ' +
    'хто залишився без житла, хто став непрацездатним та не має можливості самостійно ' +
    'заробляти на життя.\n',
  FUND_NOT_COMMERCIAL: 'Фонд є неприбутковою організацією',
  FUND_NOT_COMMERCIAL_DESC:
    'Наш фонд існує завдяки добровільним внескам від юридичних та фізичних осіб. ' +
    'Будь яка сума, що буде перерахована на рахунок нашого фонду витрачається ' +
    'на допомогу тим, хто потребує цієї допомоги. За кожну отриману гривню ми' +
    ' звітуємо!\n' +
    'Ми впевнені, що ми можемо перемогти тільки разом!',
  JUSTICE_DATA: 'Юридичні данні',
  CURRENT_ACCOUNT: 'Розрахунковий рахунок',
  LOGO_ALT: 'Логотип фонду',
  SOCIALS_ALT: 'Іконки контактних даних',
  LEGAL_TITLE: 'БО «БФ «УКРАЇНА РАЗОМ ПЕРЕМОЖЕ»',
  TAX_CODE_TITLE: 'Ідентифікаційний код юрособи',
  ADDRESS: 'Адреса',
  OWNER: 'Власник та директор',
};

export default messages;
